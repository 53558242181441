import { AuthService } from './../../providers/auth.service';
import { UtilService } from './../../providers/util.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
  }

  postCadastro(form: FormGroup) {

    if (!form.valid) {
      return false;
    }

    const values = form.value;

    if (values['senha'] !== values['confirmasenha']) {

      UtilService.callAlert('Ops!', 'As senhas precisam se coincidir', 'error');
      return false;

    }

    this.auth.postCadastro(values)
    .subscribe(
      data => {
        console.log('mensagem');
      },
      error => {
        console.log(error);
      }
    );

  }

}
