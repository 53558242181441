import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  constructor(
    private http: Http
  ) { }

  public getProducts(data: object) {

    const url = `${environment.API}busca-produtos.php`;

    return this.http.post(url, data).pipe(
      map(res => res.json())
    );

  }

  public getDetailProduct(data: object) {

    const url = `${environment.API}detalhe-produto.php`;

    return this.http.post(url, data).pipe(
      map(res => res.json())
    );

  }

  verifyDateisRange(date: string) {

    const dayLastTwoWeek = this.getLastTwoWeek();
    const dateConvert = new Date(date);

    if (dateConvert < dayLastTwoWeek) { return true; }
    return false;

  }

  private getLastTwoWeek() {

    const today = new Date();
    const twoWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);

    return twoWeeks;

  }


}
