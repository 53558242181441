import { UtilService } from './../../../../providers/util.service';
import { CotacaoService } from './../../../../providers/cotacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-checkout-products',
  templateUrl: './checkout-products.component.html',
  styleUrls: ['./checkout-products.component.scss']
})
export class CheckoutProductsComponent implements OnInit, OnDestroy {

  itemPluralMapping = {
    'produto': {
      '=0' : 'Nenhum produto',
      '=1' : '1 Produto',
      'other' : '# Produtos'
    },
    'selecionado': {
      '=0' : 'selecionado',
      '=1' : 'selecionado',
      'other' : 'selecionados'
    }
  };
  sub: Subscription;
  idCotacao: number;
  total: number;
  infosTop = {};
  listProducts = [];
  sending = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _cotacaoService: CotacaoService,
  ) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe( params => {

      this.idCotacao = params['id'];

    });

    this.listProducts = JSON.parse(localStorage.getItem('productSelect'));
    this.getTotal();
    this.infosTop = JSON.parse(localStorage.getItem('product_cotacao'));

  }

  getSubTotalProduct(valor, quantidade, valor_negociado) {

    return this._cotacaoService.getSubtotal(valor, quantidade, valor_negociado);

  }

  getTotal() {

    this.total = this._cotacaoService.getTotal(this.listProducts);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  confirmCall() { 

    this.sending = true;

    const obj = {
      codCotacao: this.idCotacao,
      produtos: this.listProducts
    };

    this._cotacaoService.sendCotacao(obj)
    .subscribe(data => {

      UtilService.callAlert(
        'Pedido concluído',
        'Você pode alterar as quantidades solicitadas até o término do prazo, acessando o menu "Meus Pedidos".',
        'success'
      );

      setTimeout(() => {

        this.router.navigate(['pedidos']);
        this.sending = false;

      }, 500);

    });


  }

}
