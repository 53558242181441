import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UtilService } from './util.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: Http,
    private router: Router,
    private util: UtilService
  ) { }

  get isLoggedIn() {

    if (!localStorage.getItem('user')) {
      this.loggedIn.next(false);
    } else {
      this.loggedIn.next(true);
    }

    return this.loggedIn.asObservable();
  }

  loginAuth(crendentials: object) {

    const url = `${environment.API}acessa.php`;

    return this.http.post(url, crendentials).pipe(
      map(res => res.json())
    );

  }

  initSession(dataSession) {

    localStorage.setItem('user', JSON.stringify(dataSession.dados));
    this.router.navigate(['/']);
  }

  public getToken() {
    const datauser = JSON.parse(localStorage.getItem('user'));
    return datauser['auth'];
  }

  public userLogged() {

    const datauser = JSON.parse(localStorage.getItem('user'));
    return datauser;

  }

  public getDataUser() {

    const url = `${environment.API}detalhe-meus-dados.php`;
    const obj = {
      auth: this.getToken()
    };

    return this.http.post(url, obj).pipe(
      map(res => res.json())
    );

  }

  public checkAuth(res) {

    if (res['mensagem'] === 'Não autorizado') {
  
      this.logoutUser();
    }

  }

  public isAuth(): any {

    const datauser = JSON.parse(localStorage.getItem('user'));
    return datauser ? true : false;

  }

  logoutUser() {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  postCadastro(crendentials: object) {

    const url = `${environment.API}cadastro.php`;

    return this.http.post(url, crendentials).pipe(
      map(res => res.json())
    );

  }

  getFaturamentoTotal() {
    const url = `${environment.API}info-negociado.php`;

    const obj = {};
    obj['auth'] = this.getToken();

    return this.http.post(url, obj)
    .pipe(
      map(res => res.json())
    );
  }

  sendForgoten(crendentials: object) {

    const url = `${environment.API}esqueci-senha.php`;

    return this.http.post(url, crendentials).pipe(
      map(res => res.json())
    );

  }

  removeNotifications(cod: any) {

    const values = {
      auth: this.getToken(),
      notificacao: cod
    };

    const url = `${environment.API}excluir-notificacao.php`;

    return this.http.post(url, values).pipe(
      map(res => res.json())
    );

  }

  passChange(senha: string) {

    const url = `${environment.API}alterar-senha.php`;

    const obj = {};
    obj['auth'] = this.getToken();
    obj['senha'] = senha;

    return this.http.post(url, obj).pipe(
      map(res => res.json())
    );

  }

  updateUser(crendentials: object) {

    const url = `${environment.API}meus-dados.php`;

    return this.http.post(url, crendentials).pipe(
      map(res => res.json())
    );

  }


  clearLastSearch() {
    localStorage.removeItem('last_consulta');
  }

}
