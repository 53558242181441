import { FormsModule } from '@angular/forms';
import { ComponentsModule } from './../components/components.module';
import { Routes, RouterModule } from '@angular/router';
import { DetailComponent } from './../../pages/pedidos/detail/detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedidosComponent } from 'src/app/pages/pedidos/pedidos.component';
import { PedidosArquivadosComponent } from 'src/app/pages/pedidos/arquivados/pedidos-arquivados.component';

const routes: Routes = [
  { path: 'pedidos', component: PedidosComponent },
  { path: 'pedidos-arquivados', component: PedidosArquivadosComponent },
  { path: 'pedidos/:id', component: DetailComponent },
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    PedidosComponent,
    PedidosArquivadosComponent,
    DetailComponent
  ]
})
export class PedidosModule { }
