import { UtilService } from './../../providers/util.service';
import { Router } from '@angular/router';
import { Component, Input, OnChanges } from '@angular/core';
import { CotacaoService } from 'src/app/providers/cotacao.service';
import { DatePipe } from '@angular/common';

export enum TiposCotacao {
  'Em aberto' = '1',
  'Em negociação' = '2',
  'Negociado'= '4',
  'Fechadas'= '3',
}

export enum TiposPedidos {
  'pendentes' = '1',
  'confirmados' = '2',
  'cancelados'= '3'
}


@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss'],
  providers: [ DatePipe ]
})
export class NavTabsComponent implements OnChanges {

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  tabActive = 0;
  @Input() tabs = [];
  @Input() orderBy: string;
  @Input() dataList = [];
  @Input() type: string;
  listFilter = [];
  loading = true;

  constructor(
    private router: Router,
    private cotacao: CotacaoService,
    private datepipe: DatePipe,
  ) { }

  swipe(item, action = this.SWIPE_ACTION.RIGHT) {

    if (item.status === 'pendentes') {
      return false;
    }

    if (!item.archive || action === 'swipeleft') {

      item.archive = true;

    }

    if (action === 'swiperight') {

      item.archive = false;

    }


  }

  archiveItem(pedido, index) {

    if (pedido) {
      pedido['acao'] = 'arquivar';

      this.cotacao.postEditPedido(pedido)
      .subscribe(data => {

        UtilService.callAlert('OK', data['resposta'], 'success');

        this.listFilter.splice(index, 1);

      });

    }

  }

  archiveAll() {

    const status = this.tabs[this.tabActive].status;

    this.cotacao.postArchive(status).subscribe(data => {

      UtilService.callAlert('OK', data['mensagem'], 'success');

      this.listFilter = [];

    });


  }

  ngOnChanges() {

    console.log(this.orderBy);

    this.changeTab(this.tabs[0].status, 0);
  }

  changeTab(status, index) {

    this.loading = true;
    this.tabActive = index;
    this.filterList(status);

  }

  filterList(status) {

    if (this.type === 'cotacao') {

      this.loadingCotacoes(status);

    }

    if (this.type === 'pedidos') {

      this.loadingPedidos(status);
    }

    setTimeout(() => {
      this.loading = false;
    }, 1200);


  }

  loadingCotacoes(status) {

    this.cotacao.getCotacoes(status)
    .subscribe(res => {

      this.listFilter = res;

    });

  }

  loadingPedidos(status) {

    this.cotacao.getPedidos(status, this.orderBy)
    .subscribe(res => {

      this.listFilter = res;

    });

  }

  saveProduct(item) {

    const date_init = new Date(item['Dtinif'].replace(/-/g, '/')).toISOString();
    const today = new Date().toISOString();

    console.log(today > date_init);
    console.log(date_init);

    if (item.pedido) {

      UtilService.callAlert('Pedido Encontrado!', 'Você será redirecionado para seu pedido!', 'success');
      this.router.navigate(['pedidos', item.numpedido]);

    } else if (item.status == 3) {

      UtilService.callAlert('Ops!', 'Está cotação está fechada!', 'error');

    } else {

      if (today > date_init) {

        this.router.navigate(['cotacoes', item.codigo, 'selecione-produtos']);
        localStorage.setItem('product_cotacao', JSON.stringify(item));

      } else {
        UtilService.callAlert('Atenção', 'Está cotação será iniciada em ' + this.datepipe.transform(date_init, 'short'), 'warning');
      }

    }


  }

}
