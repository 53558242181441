import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-products-detail',
  templateUrl: './header-products-detail.component.html',
  styleUrls: ['./header-products-detail.component.scss']
})
export class HeaderProductsDetailComponent implements OnInit {

  @Input() typeHeader: string;
  @Input() infoProduct: any;

  constructor(
    private _location: Location
  ) { }

  ngOnInit() {
  }

  backPage() {
    this._location.back();
  }

}
