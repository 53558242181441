import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AuthService } from './../../providers/auth.service';
import { UtilService } from './../../providers/util.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.scss']
})
export class EsqueciSenhaComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  postForgoten(form: FormGroup) {

    this.auth.sendForgoten(form.value)
    .subscribe(
      data => {
        UtilService.callAlert(data['titulo'], data['mensagem'], data['status']);

        if (data['status'] === 'success') {
          this.router.navigateByUrl('/login');
        }

      },
      err => {
        UtilService.callAlert(err['titulo'], err['mensagem'], err['status']);
      }
    );

  }


}
