import { Component, OnInit, OnDestroy } from '@angular/core';
import { CotacaoService } from '../../providers/cotacao.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-cotacoes',
  templateUrl: './cotacoes.component.html',
  styleUrls: ['./cotacoes.component.scss']
})
export class CotacoesComponent implements OnInit {

  sub: Subscription;
  tabsStatus = [
    { label: 'Em aberto', status: 1 },
    { label: 'Em negociaçao', status:  2 },
    { label: 'Negociada', status: 4 },
    { label: 'Fechada', status:  3 },
  ];


  constructor(
    private _cotacaoService: CotacaoService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.auth.clearLastSearch();
  }

}
