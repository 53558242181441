import { FormGroup } from '@angular/forms';
import { UtilService } from '../../../../providers/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CotacaoService } from '../../../../providers/cotacao.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-selecione-produtos',
  templateUrl: './selecione-produtos.component.html',
  styleUrls: ['./selecione-produtos.component.scss']
})
export class SelecioneProdutosComponent implements OnInit, OnDestroy {

  itemPluralMapping = {
    'produto': {
      '=0' : 'Nenhum produto',
      '=1' : '1 Produto',
      'other' : '# Produtos'
    },
    'selecionado': {
      '=0' : 'selecionado',
      '=1' : 'selecionado',
      'other' : 'selecionados'
    }
  };
  sub: Subscription;
  idCotacao: number;
  listProducts = [];
  listSelects =  [];
  loading = false;
  infosTop = {};

  constructor(
    private _cotacaoService: CotacaoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.loadProducts();

    this.infosTop = JSON.parse(localStorage.getItem('product_cotacao'));

  }

  loadProducts() {

    this.sub = this.route.params.subscribe( params => {

      this.loading = true;

      this.idCotacao = params['id'];

      this._cotacaoService.getProductsbyCotacao(this.idCotacao)
      .subscribe(data => {

        this.listProducts = data;
        this.loading = false;

      });

    });

  }

  checkSelects(e) {

    this.listSelects = this.listProducts.filter(element => {

      if (element['selecionado']) {
        element['quantidade'] = null;
        return element;
      }

    });

  }

  goToQuantity() {

    localStorage.setItem('productSelect', JSON.stringify(this.listSelects));
    UtilService.callAlert('OK', 'Selecione agora quantidade que deseja!', 'success');

    this.router.navigate(['cotacoes', this.idCotacao, 'quantidade-produtos']);

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }



}
