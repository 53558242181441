import { CotacaoService } from './../../providers/cotacao.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit {

  sub: Subscription;
  tabsStatus = [
    { label: 'Pendentes', status: 0 },
    { label: 'Confirmados', status: 1 },
    { label: 'Cancelados', status: 2 }
  ];

  listPedidos = [];

  constructor(
    private _pedidosService: CotacaoService,
    private auth: AuthService
  ) { }

  ngOnInit() {

    this.auth.clearLastSearch();
    
  }


}
