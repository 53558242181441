import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.scss']
})
export class SuporteComponent implements OnInit {

  loginStatus = false;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {

    this.auth.clearLastSearch();

  }

}
