import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import swal from 'sweetalert';
import { map, filter } from 'rxjs/operators';
import { Http } from '@angular/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private route: Router,
    private http: Http
  ) { }

  static callAlert(title, text, status) {
    return swal({
      title: title,
      text: text,
      icon: status,
    });
  }

  public  getImageProduct(foto: string) {

    if (!foto) {
      return 'assets/images/default.jpg';
    }
    return 'data:image/jpeg;base64,' + foto;

  }

  public getNotify(auth) {

    const url = `${environment.API}listar-notificacoes.php`;
    const obj = {
      auth: auth
    };

    return this.http.post(url, obj).pipe(
      map(res => res.json())
    );

  }

  static callConfirmAlert(title, text, status) {
    return swal({
      title: title,
      text: text,
      icon: status,
      buttons: [
        'Não',
        'Sim, eu quero'
      ],
      dangerMode: true,
    })
    .then(function(isConfirm) {
      if (isConfirm) {
        return true;
      } else {
       return false;
      }
    })
  }

}
