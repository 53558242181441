import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CotacaoService {

  constructor(
    private http: Http,
    private auth: AuthService
  ) { }


  getProductsbyCotacao(idCotacao: number) {

    const url = `${environment.API}detalhe-cotacao.php`;
    const obj = {
      auth: this.auth.getToken(),
      cod: idCotacao
    };

    return this.http.post(url, obj)
    .pipe(
      map(res => res.json())
    );

  }

  getSubtotal(preco, quantidade, negociado) {

    if (negociado < preco && negociado > 0) {
      return negociado * quantidade;
    }

    return preco * quantidade;


  }

  getTotal(list): number {

    let total = 0;

    list.forEach(element => {
      total += this.getSubtotal(element.preco, element.quantidade, element.preco_negociado);

    });
    return total;
  }

  sendCotacao(obj) {

    const url = `${environment.API}inserir-pedido.php`;
    obj['auth'] = this.auth.getToken();

    return this.http.post(url, obj);

  }

  getPedidos(status, orderBy) {

    let url = `${environment.API}listar-pedidos.php`;

    if (orderBy) {
      url = `${environment.API}listar-pedidos-arquivados.php`;
    }

    const token = { auth: this.auth.getToken(), status: status };

    return this.http.post(url, token)
    .pipe(
      map(res => res.json())
    );

  }

  getCotacoes(status) {

    const url = `${environment.API}listar-cotacoes.php`;
    const token = { auth: this.auth.getToken(), status: status };

    return this.http.post(url, token)
    .pipe(
      map(res => res.json())
    );

  }

  getPedidoDetail(idPedido: number) {

    const url = `${environment.API}detalhe-pedido.php`;
    const obj = { auth: this.auth.getToken(), cod: idPedido };

    return this.http.post(url, obj)
    .pipe(
      map(res => res.json())
    );

  }

  postEditPedido(obj) {

    obj['auth'] = this.auth.getToken();
    const url = `${environment.API}editar-pedido.php`;

    return this.http.post(url, obj)
    .pipe(
      map(res => res.json())
    );

  }

  postArchive(status) {

    const obj = {
      status: status,
      auth: this.auth.getToken()
    };

    const url = `${environment.API}arquivar-pedidos.php`;

    return this.http.post(url, obj)
    .pipe(
      map(res => res.json())
    );

  }

}
