import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  values: object;
  user:any;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {

    this.auth
    .getFaturamentoTotal()
    .subscribe(res => {

      this.auth.checkAuth(res);
      this.values = res;
      this.user = this.auth.userLogged();

    });

    this.auth.clearLastSearch();

  }

}
