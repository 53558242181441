import { UtilService } from './../../../../providers/util.service';
import { CotacaoService } from './../../../../providers/cotacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-quantidades-produto',
  templateUrl: './quantidades-produto.component.html',
  styleUrls: ['./quantidades-produto.component.scss']
})
export class QuantidadesProdutoComponent implements OnInit, OnDestroy {

  itemPluralMapping = {
    'produto': {
      '=0' : 'Nenhum produto',
      '=1' : '1 Produto',
      'other' : '# Produtos'
    },
    'selecionado': {
      '=0' : 'selecionado',
      '=1' : 'selecionado',
      'other' : 'selecionados'
    }
  };
  sub: Subscription;
  idCotacao: number;
  total: number;
  infosTop = {};
  listProducts = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _cotacaoService: CotacaoService,
  ) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe( params => {

      this.idCotacao = params['id'];

    });

    this.listProducts = JSON.parse(localStorage.getItem('productSelect'));
    this.getTotal();

    this.infosTop = JSON.parse(localStorage.getItem('product_cotacao'));

  }

  getSubTotalProduct(valor, quantidade, valor_negociado) {

    return this._cotacaoService.getSubtotal(valor, quantidade, valor_negociado);

  }

  changeQuantity(value, product) {

    if (!value || value < 0) {
      product['quantidade'] = 1;
    }

    this.getTotal();
  }

  removeProduct(index) {

    this.listProducts.splice(index, 1);
    UtilService.callAlert('OK', 'Produto removido', 'success');
    this.getTotal();

  }

  getTotal() {

    this.total = this._cotacaoService.getTotal(this.listProducts);
  }


  goToCheckout(form: FormGroup) {


    if (!form.valid) {

      UtilService.callAlert('Ops', 'Preencha as quantidades para todos os produtos selecionados.', 'error');

      return false;

    }


    if (this.listProducts.length < 1) {

      UtilService.callAlert('Ops', 'Selecione um produto na etapa anterior para prosseguir', 'error');
      this.router.navigate(['cotacoes', this.idCotacao, 'selecione-produtos']);
      return false;
    }

    this.listProducts.forEach(element => {

      if (!element.quantidade) {
        element.quantidade = 1;
      }

    });

    localStorage.setItem('productSelect', JSON.stringify(this.listProducts));
    this.router.navigate(['cotacoes', this.idCotacao, 'checkout-produtos']);



  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
