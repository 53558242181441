import { FormGroup } from '@angular/forms';
import { AuthService } from './../../providers/auth.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, pairwise, bufferCount } from 'rxjs/operators';
import { RoutesRecognized} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Output() searchEmit = new EventEmitter();
  busca = '';
  sub: Subscription;
  autoCompleteList = [];
  routerCode: string;
  isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (
        this.isMobile.Android() || this.isMobile.BlackBerry() ||
        this.isMobile.iOS() || this.isMobile.Opera() || this.isMobile.Windows()
      );
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}

  changed(form: FormGroup) {

    this.searchEmit.emit({
      busca: this.busca,
      auth: this.auth.getToken()
    });

    localStorage.setItem('last_consulta', this.busca);

  }

  ngOnInit() {


    const barcode = localStorage.getItem('barcode');
    const last_consulta = localStorage.getItem('last_consulta');

    if (barcode === '' || last_consulta === '') {
      localStorage.removeItem('barcode');
      localStorage.removeItem('last_consulta');
    }

    const valuesearch = barcode || last_consulta;

    this.busca = valuesearch;

    if (valuesearch) {
      this.searchEmit.emit({
        busca: valuesearch,
        auth: this.auth.getToken()
      });
    }

    localStorage.removeItem('barcode');

    if (this.isMobile.iOS()) {

      this.routerCode = '/barcode';

    } else if (this.isMobile.Android()) {

      this.routerCode = '/bareader';

    } else {
      this.routerCode = '/bareader';
    }

  }


  openQrCode() {

    this.router.navigateByUrl(this.routerCode);
  }

}
