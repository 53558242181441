import { ConsultaService } from './../../providers/consulta.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from '../../providers/util.service';
import { Produto } from 'src/app/interfaces/produto';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class ConsultaComponent implements OnInit {

  sub: Subscription;
  loading = false;
  mensagem: string;
  listProducts = [];

  constructor(
    private consultService: ConsultaService,
    private util: UtilService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {}

  searchProducts(searchdata) {

    if (searchdata['busca'] === '') {
      this.listProducts = [];
      return false;
    }

    this.loading = true;

    this.sub = this.consultService.getProducts(searchdata)
    .subscribe(
      data => {

        this.auth.checkAuth(data);

        if (data.length === 0) {
          this.mensagem = 'Nenhum produto encontrado';
        }
        this.listProducts = data;
        this.loading = false;
      },
      err => {
        UtilService.callAlert('Ops!', 'Ocorreu um erro inesperado!', 'error');
        this.loading = false;
      }
    );
  }

  public getPhoto(foto: string) {
    return this.util.getImageProduct(foto);
  }

  openPage(product: Produto) {

    if (!product.preco) {
      return false;
    }

    this.router.navigate(['/consulta', product.codigo]);

  }

  rangeDate(date: string) {
    return this.consultService.verifyDateisRange(date);
  }

}
