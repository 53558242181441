import { UtilService } from './../../providers/util.service';
import { AuthService } from './../../providers/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginStatus = false;
  showPass = false;

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {}

  postLogin(form: FormGroup) {

    this.loginStatus = true;

    this.auth.loginAuth(form.value).subscribe(
      data => {
        if (data['sucesso']) {
          UtilService.callAlert('OK!', 'Seja Bem Vindo', 'success');
          this.auth.initSession(data);
        } else {
          UtilService.callAlert('Ops!', 'Usuário ou senha inválidos!', 'error');
        }
        this.loginStatus = false;
      },
      err => {

        this.loginStatus = false;
        UtilService.callAlert('Ops!', 'Ocorreu algum erro ao tentar se logar!', 'error');
      }
    );

  }

}
