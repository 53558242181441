import { CotacoesModule } from './modules/cotacoes/cotacoes.module';
import { PagebarcodeReaderComponent } from './pages/pagebarcode/pagebarcodereader.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AuthGuardService } from './providers/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { PedidosModule } from './modules/pedidos/pedidos.module';
import { ComponentsModule } from './modules/components/components.module';

import { registerLocaleData, AsyncPipe } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InicioComponent } from './pages/inicio/inicio.component';
import { LoginComponent } from './pages/login/login.component';
import { ConsultaComponent } from './pages/consulta/consulta.component';
import { InternaComponent } from './pages/consulta/interna/interna.component';
import { PagebarcodeComponent } from './pages/pagebarcode/pagebarcode.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { EsqueciSenhaComponent } from './pages/esqueci-senha/esqueci-senha.component';
import { SuporteComponent } from './pages/suporte/suporte.component';
import { MeusDadosComponent } from './pages/meus-dados/meus-dados.component';
import { HomeComponent } from './pages/home/home.component';
import { MessagingService } from './providers/messaging-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { OfflineComponent } from './pages/offline/offline.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuardService]  },
  { path: 'consultas', component: ConsultaComponent, canActivate: [AuthGuardService] },
  { path: 'inicio', component: InicioComponent },
  { path: 'login', component: LoginComponent },
  { path: 'offline', component: OfflineComponent },
  { path: 'cadastro', component: CadastroComponent, canActivate: [AuthGuardService]  },
  { path: 'barcode', component: PagebarcodeComponent, canActivate: [AuthGuardService] },
  { path: 'bareader', component: PagebarcodeReaderComponent, canActivate: [AuthGuardService] },
  { path: 'meus-dados', component: MeusDadosComponent, canActivate: [AuthGuardService] },
  { path: 'suporte', component: SuporteComponent, canActivate: [AuthGuardService] },
  { path: 'esqueci-senha', component: EsqueciSenhaComponent},
  { path: 'consulta/:id', component: InternaComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    LoginComponent,
    ConsultaComponent,
    InternaComponent,
    PagebarcodeComponent,
    PagebarcodeReaderComponent,
    CadastroComponent,
    EsqueciSenhaComponent,
    SuporteComponent,
    MeusDadosComponent,
    HomeComponent,
    OfflineComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    PedidosModule,
    CotacoesModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    ComponentsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    RouterModule.forRoot(routes)
  ],
  providers: [
    AuthGuardService,
    MessagingService,
    AsyncPipe,
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
