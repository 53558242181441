import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SearchComponent } from './../../components/search/search.component';
import { LoadingComponent } from './../../components/loading/loading.component';
import { HeaderProductsDetailComponent } from './../../components/header-products-detail/header-products-detail.component';
import { HeaderMenuComponent } from './../../components/header-menu/header-menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavTabsComponent } from 'src/app/components/nav-tabs/nav-tabs.component';

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    BrowserAnimationsModule
  ],
  declarations: [
    HeaderMenuComponent,
    HeaderProductsDetailComponent,
    LoadingComponent,
    NavTabsComponent,
    SearchComponent
  ],
  exports: [
    HeaderMenuComponent,
    HeaderProductsDetailComponent,
    LoadingComponent,
    NavTabsComponent,
    SearchComponent
  ]
})
export class ComponentsModule { }
