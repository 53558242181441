import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MessagingService } from './providers/messaging-service';
import { AuthService } from './providers/auth.service';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'app';
  private message;
  private user;
  online$: Observable<boolean>;

  constructor(
    private messagingService: MessagingService,
    private auth: AuthService,
    private router: Router
  ) {

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.online$
    .subscribe(status_online => {

      if (!status_online) {

        this.router.navigateByUrl('/offline');

      } else {

        this.router.navigateByUrl('/');
      }


    });

  }


  ngOnInit() {

    this.auth.getDataUser()
    .subscribe(res => {

      this.auth.checkAuth(res);

      this.user = res['dados']['usuario'];
      this.messagingService.requestPermission(this.user);
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage;

    });

  }


}
