import { AuthService } from './../../providers/auth.service';
import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/providers/util.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

  menuOpen = false;
  notifyOpen = false;
  newsNotify = false;
  listNotify = [];
  user = {
    email: '',
    nome: '',
    foto: ''
  };

  constructor(
    private auth: AuthService,
    private util: UtilService
  ) { }

  ngOnInit() {
    this.user = this.auth.userLogged();
    this.loadNotify();
  }

  logout() {
    this.auth.logoutUser();
  }

  openNotify() {
    this.notifyOpen = !this.notifyOpen;
    this.newsNotify = false;
  }

  loadNotify() {

    const token = this.auth.getToken();

    this.util.getNotify(token)
    .subscribe(res => {

      this.auth.checkAuth(res);


      if (res.length > JSON.parse(localStorage.getItem('countNotify'))) {

        this.newsNotify = true;

      }

      this.listNotify = res;
      localStorage.setItem('countNotify', JSON.stringify(this.listNotify.length));

    });

  }

  removeNotify(codigo: any, index: number) {

    this.auth.removeNotifications(codigo)
    .subscribe(res => {

      if (!codigo) {
        this.listNotify = [];

      } else {
        this.listNotify.splice(index, 1);
      }
      
    });

  }

}
