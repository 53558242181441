import { ConsultaComponent } from './../consulta.component';
import { ConsultaService } from './../../../providers/consulta.service';
import { AuthService } from './../../../providers/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/providers/util.service';

@Component({
  selector: 'app-interna',
  templateUrl: './interna.component.html',
  styleUrls: ['./interna.component.scss']
})
export class InternaComponent implements OnInit, OnDestroy {

  dataSend: object;
  product: any;
  sub: Subscription;
  loading = false;

  constructor(
    private router: ActivatedRoute,
    private auth: AuthService,
    private consultaService: ConsultaService,
    private route: Router,
    public util: UtilService
  ) { }

  ngOnInit() {

    this.sub = this.router.params.subscribe(res => {

      this.loading = true;

      this.dataSend = {
        cod: res['id'],
        auth: this.auth.getToken()
      };

      this.consultaService.getDetailProduct(this.dataSend)
      .subscribe(data => {
        this.product = data;
        this.loading = false;
      });

    });

  }

  rangeDate(date: string) {
    return this.consultaService.verifyDateisRange(date);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getPhoto(foto: string) {

    return this.util.getImageProduct(foto);

  }

  backPage() {
    this.route.navigateByUrl('/consultas');
  }

  comparePrice(valorSearch, valorCurrent) {

    if (valorSearch < valorCurrent) {
      return '+';
    }
    return '';
  }

}
