import { FormGroup } from '@angular/forms';
import { UtilService } from './../../providers/util.service';
import { AuthService } from './../../providers/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-meus-dados',
  templateUrl: './meus-dados.component.html',
  styleUrls: ['./meus-dados.component.scss']
})
export class MeusDadosComponent implements OnInit, OnDestroy {

  sub: Subscription;
  user: any;
  passState = false;
  loading = false;
  senha: number;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {

    this.loading = true;

    this.auth.clearLastSearch();

    this.sub = this.auth.getDataUser()
    .subscribe(data => {
      this.user = data['dados'];
      this.loading = false;
    });

  }

  changePass(senha: string) {

    if (senha.length < 6) {

      UtilService.callAlert('Ops!', 'Sua senha deve ter no minímo 6 caracteres', 'error');
      return false;

    }

    this.auth.passChange(senha)
    .subscribe(
      data => {
        UtilService.callAlert(data['titulo'], data['mensagem'], data['status']);
        this.passState = false;
      },
      err => {
        UtilService.callAlert(err['titulo'], err['mensagem'], err['status']);
      }
    );

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
