import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import Quagga from 'quagga';
import { UtilService } from '../../providers/util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagebarcodereader',
  templateUrl: './pagebarcodereader.component.html',
  styleUrls: ['./pagebarcode.component.scss']
})
export class PagebarcodeReaderComponent implements OnInit {

  barcode = '';

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {

    this.startScanner();

  }

  startScanner() {

    this.barcode = '';
    this.ref.detectChanges();

    const configQuagga  =  {
      inputStream : {
        name : 'Live',
        type : 'LiveStream',
        target: document.querySelector('#yourElement')
      },
      decoder : {
        readers: ['ean_reader', 'ean_8_reader']
      }
    };

    Quagga.init(configQuagga, (err) => {
      if (err) {
        console.log(err);
        return UtilService.callAlert('Ops', 'Dispositivo não reconhecido', 'error');
      }
      Quagga.start();
    });

    Quagga.onDetected((result) => this.logCode(result));

  }

  private logCode(result) {
    const code = result.codeResult.code;
    if (this.barcode !== code) {
      this.barcode = code;
      this.ref.detectChanges();
      UtilService.callAlert('OK!', 'Código escaneado: ' + code, 'success');
      localStorage.setItem('barcode', code);
      this.router.navigateByUrl('/consultas');
      Quagga.stop();
    }

  }

  backPage() {
    this.router.navigateByUrl('/consultas');
  }


}
