import { Directive, ElementRef, Renderer2, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputSelect]'
})
export class InputSelectDirective {

  constructor(
    private el: ElementRef,
    private render: Renderer2
  ) {}

}
