import { UtilService } from './../../../providers/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CotacaoService } from './../../../providers/cotacao.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import swal from 'sweetalert';
import { debounceTime } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {

  sub: Subscription;
  codPedido: number;
  pedido: any = new Object();
  infosTop = new Object();
  loading = false;
  textArea = {
    titulo: '',
    descricao: ''
  };
  mySubject = new Subject();

  constructor(
    public _pedidosService: CotacaoService,
    public router: ActivatedRoute,
    public util: UtilService,
    public route: Router,
    private _location: Location
  ) {

    this.sub = this.mySubject.pipe(debounceTime(1000))
    .subscribe(val => {

      const produto = val['produto'];
      const quantidade_negociada = produto['quantidade_negociacao'];

      if (val['value'] < quantidade_negociada) {

        UtilService.callAlert('Ops!', `A quantidade selecionada deve ser maior ou igual a ${quantidade_negociada}`, 'error');
        produto['quantidade'] = produto['qnt_pre'];

      }

    });

  }

  backPage() {

    this._location.back();

  }

  ngOnInit() {

    this.sub = this.router.params.subscribe(data => {

      this.codPedido = data['id'];

      this.loading = true;

      this._pedidosService.getPedidoDetail(this.codPedido)
      .subscribe(response => {

        this.pedido = response;
        this.infosTop = this.pedido;

        if (response.status === 'pendentes') {
          this.textArea['titulo'] = 'Seu pedido está sendo realizado.';
          if (response.cotacao_status == '1') {
            this.textArea['titulo'] = 'A cotação do seu pedido está em aberto.';
            this.textArea['descricao'] = 'Você ainda pode alterar as quantidades solicitadas até o término do prazo.';
          }
          else if (response.cotacao_status == '2') {
            this.textArea['titulo'] = 'A cotação do seu pedido está em negociação.';
            this.textArea['descricao'] = 'Após o término da negociação você poderá aumentar as quantidades solicitadas.';
          }
          else if (response.cotacao_status == '4') {
            this.textArea['titulo'] = 'A cotação do seu pedido está negociada.';
            this.textArea['descricao'] = 'Você ainda pode aumentar as quantidades solicitadas até o término do prazo.';
          }
          else {
            this.textArea['descricao'] = 'Você ainda pode aumentar as quantidades solicitadas até o término do prazo.';
          }

        } else if (response.status === 'confirmados') {
          this.textArea['titulo'] = 'Seu pedido está confirmado.';
          this.textArea['descricao'] = 'Entraremos em contato para negociar a entrega e pagamento.';
        } else {
          this.textArea['titulo'] = 'Seu pedido foi cancelado!';
          this.textArea['descricao'] = response['mensagem'];
        }

        this.pedido.produtos.forEach(element => {

          element['qnt_pre'] = element['quantidade'];

        });


        this.loading = false;

      });

    });


  }

  getPhoto(foto: string) {
    return this.util.getImageProduct(foto);
  }


  removeItem(index) {


    if (this.pedido.produtos.length === 1) {

      this.pedido['acao'] = 'cancelar';

      const alert_status = swal({
        title: 'Atenção!',
        text: 'Excluindo o último produto deste pedido você estará cancelando o mesmo.',
        icon: 'warning',
        buttons: [
          'Não',
          'Sim, desejo cancelar'
        ],
        dangerMode: true,
      }).then(function(isConfirm) {

        return isConfirm;

      });

      alert_status.then(
        value => {

        if (value) {

          this._pedidosService.postEditPedido(this.pedido)
          .subscribe(data => {

            UtilService.callAlert('OK', data['resposta'], 'success');
            this.route.navigateByUrl('/pedidos');

          });

        }

      });

    } else {
      this.pedido.produtos.splice(index , 1);
    }


  }

  cancelPedido() {

    this.pedido['acao'] = 'cancelar';

    const alert_status = swal({
      title: 'Atenção!',
      text: 'Você quer realmente cancelar seu pedido?',
      icon: 'warning',
      buttons: [
        'Não',
        'Sim, eu quero'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {

      return isConfirm;

    });

    alert_status.then(value => {


      if (value) {

        this._pedidosService.postEditPedido(this.pedido)
        .subscribe(data => {

          UtilService.callAlert('OK', data['resposta'], 'success');
          this.route.navigateByUrl('/pedidos');

        });

      } else {
        return false;
      }

    });

  }

  sendPedido(status) {

    this.pedido['acao'] = status;

    if (status === 'cancelar') {

      this.cancelPedido();

    } else {


      this._pedidosService.postEditPedido(this.pedido)
      .subscribe(data => {

        UtilService.callAlert('OK', data['resposta'], 'success');
        this.route.navigateByUrl('/pedidos');

      });

    }



  }

  changeQuantity(value, produto) {

    if (this.pedido.cotacao_status == 1) {
      return false;
    }

    const object_compare = {
      produto: produto,
      value: value
    };

    this.mySubject.next(object_compare);


  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}
