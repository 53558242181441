import { FormsModule } from '@angular/forms';
import { CheckoutProductsComponent } from './../../pages/cotacoes/steps/checkout-products/checkout-products.component';
import { SelecioneProdutosComponent } from './../../pages/cotacoes/steps/selecione-produtos/selecione-produtos.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CotacoesComponent } from 'src/app/pages/cotacoes/cotacoes.component';
import { QuantidadesProdutoComponent } from 'src/app/pages/cotacoes/steps/quantidades-produto/quantidades-produto.component';
import { ComponentsModule } from '../components/components.module';
import { InputSelectDirective } from 'src/app/directives/input-select.directive';

const routes: Routes = [
  { path: 'cotacoes', component: CotacoesComponent },
  { path: 'cotacoes/:id/selecione-produtos', component: SelecioneProdutosComponent },
  { path: 'cotacoes/:id/quantidade-produtos', component: QuantidadesProdutoComponent },
  { path: 'cotacoes/:id/checkout-produtos', component: CheckoutProductsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    CotacoesComponent,
    InputSelectDirective,
    SelecioneProdutosComponent,
    QuantidadesProdutoComponent,
    CheckoutProductsComponent
  ]
})
export class CotacoesModule { }
