import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import Quagga from 'quagga';
import { UtilService } from '../../providers/util.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-pagebarcode',
  templateUrl: './pagebarcode.component.html',
  styleUrls: ['./pagebarcode.component.scss']
})
export class PagebarcodeComponent implements OnInit {

  barcode = '';
  state = {
    inputStream: { size: 800 },
    locator: { patchSize: 'medium', halfSample: false },
    numOfWorkers: 4,
    decoder: {
      readers: ['ean_reader', 'ean_8_reader']
    },
    locate: false,
    src: null
  };

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  decode(src) {

    const self = this, config = $.extend({}, this.state, {src: src});

    const logCode = (result) => {

      const code = result;
      this.barcode = code;

      localStorage.setItem('barcode', code);

      setTimeout(() => {
        this.router.navigateByUrl('');
      }, 500);


    };

    Quagga.decodeSingle(config, function(result) {});

    Quagga.onProcessed(function(result) {

      const drawingCtx = Quagga.canvas.ctx.overlay, drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(function (box) {
              return box !== result.box;
          }).forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: 'green', lineWidth: 2});
          });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: '#00F', lineWidth: 2});
        }

        if (result.codeResult && result.codeResult.code) {

          logCode(result.codeResult.code);
          Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
        }

      }

    });

    Quagga.onDetected(
      (result) => {}
    );


  }

  selectPicture(e) {

    const file = e.target.files;

    if (file && file.length) {
      this.decode(URL.createObjectURL(file[0]));
    }

  }

  backPage() {
    this.router.navigateByUrl('/consultas');
  }

}
